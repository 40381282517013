import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "react-toastify/dist/ReactToastify.css";
import Header from "./common/Header";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProductAdd from "./Admin Panel/ProductAdd";
import PackagePlan from "./Admin Panel/PackagePlan";
import Login from "./Admin Panel/Login";
import Layout from "./Layout";
import RequireAuth from "./Admin Panel/RequireAuth";
import Orders from "./Admin Panel/Orders";
import Customers from "./Admin Panel/Customers";
import Dashboard from "./Admin Panel/Dashboard";
import ProductList from "./Admin Panel/ProductList";
import Services from "./Admin Panel/Services";
import Banner from "./Admin Panel/Banner";
import SubServices from "./Admin Panel/SubServices";
import AddAboutUs from "./Admin Panel/AddAboutUs";
import ChangePassword from "./Admin Panel/ChangePassword";
import { ToastContainer } from "react-toastify";
import FitCheck from "./Admin Panel/FitCheck";
import FeatureProduct from "./FeatureProduct";

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<Login />} path="/" />
        <Route element={<ChangePassword />} path="/changepassword" />

        {/* protected routes */}
        <Route element={<Layout />}>

          <Route element={<Dashboard />} path="/dashboard" />
          <Route element={<RequireAuth />}>
            <Route element={<Orders />} path="orders" />
            <Route element={<PackagePlan />} path="packageadd" />
            <Route element={<ProductAdd />} path="productadd" />
            <Route element={<Customers />} path="customers" />
            <Route element={<ProductList />} path="productlist" />
            <Route element={<Services />} path="services" />
            <Route element={<Banner />} path="banner" />
            <Route element={<AddAboutUs />} path="/aboutus" />

            <Route element={<SubServices />} path="subservices" />
            <Route element={<FitCheck />} path="fitcheck" />
            <Route element={<FeatureProduct />} path="featureproduct" />

          
          </Route>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
