import api from "../Api/api";

const handleDelete = async (id, url) => {
  try {
    var response = await api.get(`admin/${url}/${id}`);
    return response;
  } catch (error) {
    console.log(error);
    return false;
  }
};
export {handleDelete}