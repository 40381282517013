import React from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ToastContainer, toast } from "react-toastify";
import api, { imageapi } from "../Api/api";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { handleDelete } from "../components/handleDelete";

const FitCheck = () => {
  const navigate = useNavigate();
  const [url, setUrl] = useState();
  const [urlLabel, setUrlLabel] = useState();
  const [picture, setPicture] = useState();
  const [showAdd, setShowAdd] = useState(false);

  const mutationsubmit = useMutation(
    (body) => {
      const headers = {
        headers: {
          "content-type": "application/json",
        },
      };
      return api.post("admin/addfitcheck", body, headers);
    },
    {
      onError: (error, variables, context) => {
        console.log(error);
        toast.error("server error!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      },
      onSuccess: (data, variables, context) => {
        toast.success("Data Submitted", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        fitcheckrefetch()
      },
    }
  );

  const { data: fitcheckdata, refetch: fitcheckrefetch } = useQuery(
    ["fdata"],
    () => api.get(`admin/getfitcheckdata`),
    {
      select: (res) => res.data,
    }
  );

  const handleSubmit = () => {
    var formData = new FormData();
    formData.append("url", url);
    formData.append("url_label", urlLabel);
    formData.append("picture", picture);
    mutationsubmit.mutate(formData);
  };

  const columns = [
    {
      name: "S.No.",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row.url,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.url_label,
      sortable: true,
    },
    {
      name: "Category Image",
      selector: (row) => {
        return (
          <>
            <img
              src={`${imageapi}/images/${row.picture}`}
              width="100px"
              alt=""
            />
          </>
        );
      },
      sortable: true,
    },
    {
      button: true,
      width: "200px",
      cell: (item) => {
        return (
          <div className="actionBtn">
            
            <button
              type="button"
              onClick={() => {
                handleDelete(item.id, "deletefitcheck");
                fitcheckrefetch();
              }}
            >
              <i class="fa-solid fa-trash"></i>
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="mainBody">
      <div class="innerPageSection">
        <div class="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Button
                className="btn add_nav_btn btn-primary"
                onClick={() => setShowAdd(!showAdd)}
              >
                Add New
              </Button>
              <Button
                className="btn add_nav_btn btn-primary"
                onClick={() => navigate("/featureproduct")}
              >
                Add Feature Product
              </Button>
              {showAdd && (
                <div className="formSection">
                  <div className="subHeading">
                    <h3>Gallery</h3>
                  </div>
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Title"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Description"
                        value={urlLabel}
                        onChange={(e) => setUrlLabel(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Control
                        type="file"
                        onChange={(e) => setPicture(e.target.files[0])}
                      />
                    </Form.Group>
                    <Button variant="dark" onClick={handleSubmit}>
                      Add
                    </Button>
                  </Form>
                </div>
              )}
            </div>
          </div>
          <DataTable columns={columns} data={fitcheckdata?.data} />
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  );
};

export default FitCheck;
