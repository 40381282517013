import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useQuery, useMutation } from "@tanstack/react-query";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import api from "../Api/api";
import { toast } from "react-toastify";
const Login = () => {
  const { auth, setAuth } = useAuth();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [user, setUser] = useState({});

  const navigate = useNavigate();
  const mutationlogin = useMutation(
    (body) => {
      const headers = {
        headers: {
          "content-type": "application/json",
        },
      };
      return api.post("admin/login", body, headers);
    },
    {
      onError: (error, variables, context) => {
        console.log(error);
      },
      onSuccess: (data, variables, context) => {
        if (data.data.status) {
          setUser(data.data);
          setAuth({ user: data.data });
          localStorage.setItem("token", data.data.refreshtoken);
          setTimeout(() => {
            navigate("/dashboard");
          }, 1000);
        } else {
          toast.error('You are not admin !', {
            position: toast.POSITION.TOP_CENTER
        });
          setEmail("");
          setPassword("");
        }
      },
    }
  );

  const handleLogin = () => {
    var body = { username: email, password: password };
    mutationlogin.mutate(body);
  };

  return (
    <div className="loginPage">
      <div className="loginSection">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="loginForm">
                <Form>
                  <h2> Login</h2>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                    {/* <Form.Text className="text-muted">
                      We'll never share your email with anyone else.
                    </Form.Text> */}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />
                  </Form.Group>
                  {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Check me out" />
                  </Form.Group> */}
                  <Button variant="primary" onClick={() => handleLogin()}>
                    Submit
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
