import React from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Header from "./common/Header";
const Layout = () => {
  return (
    <div>
        <Header/>
        <ToastContainer />

      <Outlet />
    </div>
  );
};

export default Layout;
