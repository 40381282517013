import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useQuery } from "@tanstack/react-query";
import Dropzone from "react-dropzone";
import { set } from "react-hook-form";
import { useEffect } from "react";
import api from "../Api/api";
import { toast } from "react-toastify";

const ProductAdd = () => {
  const [proname, setProname] = useState();
  const [price, setPrice] = useState();
  const [description, setDescription] = useState();
  const [images, setImages] = useState([]);
  const [serviceid, setServiceid] = useState();
  const [upload, setUpload] = useState(true);
  const [subServiceid, setSubServiceid] = useState();

  const {
    data: productadddata,

    refetch,
  } = useQuery(
    ["prodata"],
    () =>
      api.get(
        `admin/productadd/${proname}/${price}/${description}/${serviceid}`
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,

      select: (res) => res.data,
    }
  );

  //

  const {
    data: servicedata,

    refetch: servicerefetch,
  } = useQuery(["services"], () => api.get(`admin/getallservices`), {
    select: (res) => res.data,
  });

  const {
    data: subservicedata,

    refetch: subservicerefetch,
  } = useQuery(
    ["subservices", serviceid],
    () => api.get(`admin/getsubservices/${serviceid}`),
    {
      select: (res) => res.data,
    }
  );


useEffect(()=>{
  toast.success("Submitted!", {
    position: toast.POSITION.TOP_CENTER,
  });
},[productadddata])


  const handleImagesSubmit = async () => {
    var formData = new FormData();
    formData.append("id", parseInt(productadddata.insertid));
    images &&
      images.forEach((item, index) => {
        formData.append("picture" + index, item);
      });
    const headers = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    var response = await api.post("admin/uploadimages", formData, headers);
    console.log(response.status);
if(response.status){
  toast.success('Image Updated Successfuly!', {
    position: toast.POSITION.TOP_RIGHT
});
}


  };

  const handleSubmit = () => {
    setUpload(false);
    refetch();

    setTimeout(() => {
      setDescription("");
      setPrice("");
      setProname("");
    }, 2000);
  };

  const handleImages = (files) => {
    var tempImages = [...images, ...files];
    // console.log(tempImages)
    setImages([...tempImages]);
  };

  return (
    <div className="mainBody">
      <div class="innerPageSection">
        <div class="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="formSection">
                <div className="subHeading">
                  <h3>Add Package</h3>
                </div>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Package Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Product Name"
              value={proname}
              onChange={(e) => setProname(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              placeholder="Amount"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          
          <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Category Name</Form.Label>

          <Form.Select
            aria-label="Default select example"
            value={serviceid}
            onChange={(e) => setServiceid(e.target.value)}
            // className="mt-4"
          >
            <option value="#">--select--</option>

            {servicedata?.data.map((item) => {
              return <option value={item.id}>{item.name}</option>;
            })}
          </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Sub Category</Form.Label>

          <Form.Select
            aria-label="Default select example"
            value={subServiceid}
            onChange={(e) => setSubServiceid(e.target.value)}
            // className="mt-4"
          >
            <option selected value="0">
              --select--
            </option>

            {subservicedata?.data.map((item) => {
              return <option value={item.id}>{item.name}</option>;
            })}
          </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3 mt-3" controlId="formBasicPassword">
            <Form.Label>Image</Form.Label>

            <Dropzone onDrop={(acceptedFiles) => handleImages(acceptedFiles)}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Upload Multiple Images</p>
                  </div>
                </section>
              )}
            </Dropzone>
          </Form.Group>
          <Button variant="dark" onClick={() => handleSubmit()}>
            Submit
          </Button>
          <Button
            className="mml"
            variant="dark"
            disabled={upload}
            onClick={() => handleImagesSubmit()}
          >
            Upload
          </Button>
        </Form>
      
        </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductAdd;
