import { useMutation } from "@tanstack/react-query";
import React from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../Api/api";
import useAuth from "../hooks/useAuth";
import Dropdown from "react-bootstrap/Dropdown";
import { NavDropdown } from "react-bootstrap";

const Header = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const mutationlogout = useMutation(
    (body) => {
      var authstring = auth.token ?? auth.user.refreshtoken;
      const headers = {
        headers: {
          authorization: "bearer" + " " + authstring,
        },
      };
      return api.post("admin/logout", body, headers);
    },
    {
      onError: (error, variables, context) => {
        console.log(error);
      },
      onSuccess: (data, variables, context) => {
        console.log("here");
        localStorage.clear();
        setAuth({});

        navigate("/");
      },
    }
  );

  const handleMutate = () => {
    mutationlogout.mutate({ token: auth.token ?? auth.user.refreshtoken });
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setAuth({ token: localStorage.getItem("token") });
    }
  }, []);

  return (
    <div>
      <header>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <div className="logo">
                <Link to="/dashboard">
                  <a>
                    <img src="logo.png" className="img-fluid" />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-md-9">
              <div className="settingDropdown">
                <button className="dropbtn">
                  <img src="/icons/profile.png" alt="" />
                  {/* <img src="assets/images/icons/profile.png"> */}
                </button>
                <ul className="dropdown-content">
                  <li>
                    <a onClick={() => navigate("/changepassword")}>
                      <i className="fa fa-key" aria-hidden="true"></i>Change
                      Password
                    </a>
                  </li>
                  <li>
                    <a onClick={() => handleMutate()}>
                      <i className="fas fa-sign-out-alt"></i>Log Out
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </header>

      <div
        id="mySidenav"
        className="sidenav"
        style={{ width: "15%", fontSize: 15, fontWeight: 500 }}
      >
        <a href="" className="closebtn" onClick="closeNav()">
          ×
        </a>

        <ul className="sideNavMenu">
          <li>
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li>
            <Link to="/banner">Banner</Link>
          </li>

          <li>
            <NavDropdown
              id="nav-dropdown-dark-example"
              title="Services"
              // menuVariant="primary"
            >
              <NavDropdown.Item>
                {" "}
                <Link to="/services">Category</Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/subservices">Sub Category</Link>
              </NavDropdown.Item>
            </NavDropdown>
          </li>

          <li>
            <Link to="/productlist">Packages</Link>
          </li>
          <li>
            <Link to="/packageadd">Options</Link>
          </li>
          <li>
            <Link to="/orders">Orders</Link>
          </li>
          <li>
            <Link to="/customers">Customers</Link>
          </li>
          <li>
            <Link to="/fitcheck">Gallery</Link>
          </li>
        </ul>
      </div>

      <div className="main_menu" onClick="openNav()">
        <img src="/icons/menu.png" alt="" />
        {/* <img src="assets/images/icons/menu.png"> */}
      </div>
    </div>
  );
};

export default Header;
