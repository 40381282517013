import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useState } from "react";
import api, { imageapi } from "../Api/api";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import { handleDelete } from "../components/handleDelete";
const Services = () => {
  const [servicename, setServicename] = useState();
  const [type, setType] = useState(0);
  const [picture, setPicture] = useState();
  const [hasSubservices, setHasSubservices] = useState(0);
  const [show, setShow] = useState(false);
  const [showImageDialog, setShowImageDialog] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [serviceid, setServiceid] = useState();

  const { data: servicedata, refetch } = useQuery(
    ["sdata"],
    () => api.get(`admin/getallservices`),
    {
      select: (res) => res.data,
    }
  );

  const mutationservices = useMutation(
    (body) => {
      const headers = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      return api.post("admin/addservices", body, headers);
    },
    {
      onError: (error, variables, context) => {
        console.log(error);
        toast.error("server error !", {
          position: toast.POSITION.TOP_CENTER,
        });
      },
      onSuccess: (data, variables, context) => {
        toast.success("Submitted!", {
          position: toast.POSITION.TOP_CENTER,
        });
        refetch();
      },
    }
  );

  const mutationimage = useMutation(
    (body) => {
      const headers = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      return api.post("admin/updateserviceimage", body, headers);
    },
    {
      onError: (error, variables, context) => {
        console.log(error);
        toast.error("server error !", {
          position: toast.POSITION.TOP_CENTER,
        });
      },
      onSuccess: (data, variables, context) => {
        toast.success("Image Updated!", {
          position: toast.POSITION.TOP_CENTER,
        });
        refetch();
      },
    }
  );

  const handleEdit = (item) => {
    setServicename(item.name);
    setType(item.type);
    setHasSubservices(item.hasSubservices);
    setServiceid(item.id);
    setShow(true);
  };
  const mutationservicesupdate = useMutation(
    (body) => {
      const headers = {
        headers: {
          "content-type": "application/json",
        },
      };
      return api.post("admin/updateservices", body, headers);
    },
    {
      onError: (error, variables, context) => {
        console.log(error);
        toast.error("server error !", {
          position: toast.POSITION.TOP_CENTER,
        });
      },
      onSuccess: (data, variables, context) => {
        toast.success("Submitted!", {
          position: toast.POSITION.TOP_CENTER,
        });
        refetch();
      },
    }
  );

  const handleEditImageSubmit = () => {
    const formData = new FormData();
    formData.append("serviceid", serviceid);

    formData.append("picture", picture);
    mutationimage.mutate(formData);
  };

  const handleEditImage = (item) => {
    setServiceid(item.id);
    setShowImageDialog(true);
  };

  const handlePicture = (e) => {
    setPicture(e.target.files[0]);
  };

  const handleClose = () => {
    setShow(false);
    setShowImageDialog(false);
  };

  const showDialog = () => {
    return (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Update Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Category Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  value={servicename}
                  onChange={(e) => setServicename(e.target.value)}
                />
                <Form.Text className="text-muted"></Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Contains Options (yes/no)?</Form.Label>

                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setType(e.target.value)}
                  value={type}
                >
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Includes Sub Category? (yes/no)?</Form.Label>

                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setHasSubservices(e.target.value)}
                  value={hasSubservices}
                >
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </Form.Select>
              </Form.Group>
              <Button
                className="mt-3"
                variant="dark"
                onClick={() => handleEditSubmit()}
              >
                Submit
              </Button>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const imageDialog = () => {
    return (
      <>
        <Modal show={showImageDialog} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Update Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Image</Form.Label>
                <Form.Control
                  type="file"
                  placeholder="upload"
                  onChange={(e) => handlePicture(e)}
                />
              </Form.Group>

              <Button
                className="mt-3"
                variant="dark"
                onClick={() => handleEditImageSubmit()}
              >
                Update
              </Button>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const handleEditSubmit = () => {
    var body = {
      servicename: servicename,
      type: type,
      hassubservices: hasSubservices,
      serviceid,
    };
    mutationservicesupdate.mutate(body);
  };

  const handleSubmit = () => {
    var formData = new FormData();
    formData.append("servicename", servicename);
    formData.append("type", type);
    formData.append("hassubservices", hasSubservices);

    formData.append("picture", picture);
    setTimeout(() => {
      mutationservices.mutate(formData);
    }, 1000);
  };

  const columns = [
    {
      name: "S.No.",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Category Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Category Image",
      selector: (row) => {
        return (
          <>
            <img
              src={`${imageapi}/images/${row.picture}`}
              width="100px"
              alt=""
            />
          </>
        );
      },
      sortable: true,
    },
    {
      button: true,
      width: "200px",
      cell: (item) => {
        return (
          <div className="actionBtn">
            <button
                type="button"
                // className="btn btn-primary"
                onClick={() => handleEdit(item)}
              >
                <i class="fa-solid fa-pen-to-square"></i>
              </button>
            <button
                type="button"
                onClick={() => {
                  handleDelete(item.id, "deleteservices");
                  refetch();
                }}
              >
                <i class="fa-solid fa-trash"></i>
              </button>
              <button
                type="button"
                className="btn2"
                onClick={() => handleEditImage(item)}
              >
                Edit Image
              </button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="mainBody">
        <div class="innerPageSection">
          <div class="container-fluid">
            <div className="row">
              <div className="col-md-12">
                    <Button
                      className="btn add_nav_btn btn-primary"
                      onClick={() => setShowAdd(!showAdd)}
                    >
                      Add New
                    </Button>
                  {showAdd && (
                <div className="formSection">
                    <Form>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Category Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter name"
                          value={servicename}
                          onChange={(e) => setServicename(e.target.value)}
                        />
                        <Form.Text className="text-muted"></Form.Text>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Image</Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="upload"
                          onChange={(e) => handlePicture(e)}
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Contains Options (yes/no)?</Form.Label>

                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => setType(e.target.value)}
                          value={type}
                        >
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </Form.Select>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Includes Sub Category? (yes/no)?</Form.Label>

                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => setHasSubservices(e.target.value)}
                          value={hasSubservices}
                        >
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </Form.Select>
                      </Form.Group>
                      <Button
                        className="mt-3"
                        variant="dark"
                        onClick={() => handleSubmit()}
                      >
                        Submit
                      </Button>
                    </Form>
                </div>
                  )}
                <div className="formSection">
                  <div className="subHeading">
                    <h3>Category List</h3>
                  </div>

                  <DataTable columns={columns} data={servicedata?.data} />
                </div>
              </div>
            </div>
            {showDialog()}
            {imageDialog()}
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
