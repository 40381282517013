import React from "react";
import DataTable from "react-data-table-component";
import { useQuery, useMutation } from "@tanstack/react-query";
import useAuth from "../hooks/useAuth";

import { useState } from "react";
import api from "../Api/api";

const Orders = () => {
  const [details, setDetails] = useState(false);
  const [orderid, setOrderid] = useState();

  const { auth } = useAuth();
  const { data: prodata } = useQuery(
    ["logdata", orderid],
    () => api.get(`admin/getorderproducts/${orderid}`),
    {
      select: (res) => res.data,
    }
  );

  const { data: packdata, refetch: packrefetch } = useQuery(
    ["packdata", orderid],
    () => api.get(`admin/getorderpackages/${orderid}`),
    {
      select: (res) => res.data,
    }
  );

  const { data: orderdata, refetch: orderrefetch } = useQuery(
    ["orderdata"],
    () => api.get(`admin/getorders`),
    {
      select: (res) => res.data,
    }
  );

  const columns = [
    {
      name: "S.No.",
      selector: (row, index) => index + 1,
      sortable: true,
    },

    {
      name: "Price",
      selector: (row) => row.totalamount,
      sortable: true,
    },
    {
      name: "Qty",
      selector: (row) => row.quantity,
      sortable: true,
    },
    {
      name: "Total Amount",
      selector: (row) => row.totalamount,
      sortable: true,
    },
  ];

  const packcolumns = [
    {
      name: "S.No.",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Package Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: "Qty",
      selector: (row) => row.cartquantity,
      sortable: true,
    },
    {
      name: "Total Amount",
      selector: (row) => row.cartamount,
      sortable: true,
    },
  ];

  const handleShowDetails = (item) => {
    setOrderid(item.order_no);
    setDetails(true);
  };

  const ordercolumns = [
    {
      name: "S.No.",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Customer Name",
      selector: (row) => row.customer_name,
      sortable: true,
    },
    // {
    //   name: "Transaction id",
    //   selector: (row) => row.transactionid,
    //   sortable: true,
    // },
    {
      name: "Total Amount",
      selector: (row) => row.totalamount,
      sortable: true,
    },
    {
      name: "Payment Status",
      selector: (row) => row.payment_status,
      sortable: true,
    },
    {
      name: "Order Status",
      selector: (row) => row.order_status,
      sortable: true,
    },
    {
      name: "Details",
      cell: (row) => (
        <button
          type="button"
          class="btn btn-dark"
          data-bs-toggle="modal"
          data-bs-target="#myModal"
          onClick={() => handleShowDetails(row)}
        >
          Show Details
        </button>
      ),
    },
  ];

  return (
    <div className="mainBody">
      <div class="innerPageSection">
        <div class="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="formSection">
                <div className="subHeading">
                  <h3>Orders</h3>
                </div>
        {details ? (
          <>
            <div>
              <DataTable
                title="Products"
                columns={columns}
                data={prodata?.data}
              />
            </div>
            <div>
              <DataTable
                title="Packages"
                columns={packcolumns}
                data={packdata?.data}
              />
            </div>
          </>
        ) : (
          <>
            <div>
              <DataTable
                // title="Orders"
                columns={ordercolumns}
                data={orderdata?.data}
              />
            </div>
          </>
        )}
        </div>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  );
};

export default Orders;
