import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useQuery, useMutation } from "@tanstack/react-query";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../Api/api";
import { ToastContainer, toast } from "react-toastify";
const ChangePassword = () => {
  const [currPassword, setCurrPassword] = useState();
  const [newPassword, setNewPassword] = useState();

  const navigate = useNavigate();
  const mutationCheck = useMutation(
    (body) => {
      const headers = {
        headers: {
          "content-type": "application/json",
        },
      };
      return api.post("admin/changepassword", body, headers);
    },
    {
      onError: (error, variables, context) => {
        console.log(error);
      },
      onSuccess: (data, variables, context) => {
        if (data.data.status) {
            toast.success("Password Changed Successfuly", {
                position: toast.POSITION.TOP_CENTER,
              });  
              navigate('/dashboard')
        } else {
          toast.error("You are not admin !", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      },
    }
  );

  const handleLogin = () => {
    var body = { currpassword: currPassword, newpassword: newPassword };
    mutationCheck.mutate(body);
  };

  return (
    <div className="loginPage">
      <div className="loginSection">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="loginForm">
                <Form>
                  <h2> Change Password</h2>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Current Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter current password"
                      onChange={(e) => setCurrPassword(e.target.value)}
                      value={currPassword}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter New Password"
                      onChange={(e) => setNewPassword(e.target.value)}
                      value={newPassword}
                    />
                  </Form.Group>

                  <Button variant="primary" onClick={() => handleLogin()}>
                    Submit
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
