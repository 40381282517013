import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import api from "../Api/api";
import { Form } from "react-bootstrap";
import Dropzone from "react-dropzone";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast} from "react-toastify";
import { handleDelete } from "../components/handleDelete";

const ProductList = () => {
  const [proname, setProname] = useState("");
  const [price, setPrice] = useState();
  const [proid, setProid] = useState();

  const [description, setDescription] = useState();
  const [images, setImages] = useState([]);
  const [serviceid, setServiceid] = useState();
  const [upload, setUpload] = useState(true);
  const [subServiceid, setSubServiceid] = useState();
  const [show, setShow] = useState(false);
  const { data: prodata, refetch: prorefetch } = useQuery(
    ["prodata"],
    () => api.get(`admin/getproductlist`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      select: (res) => {
        return res.data;
      },
    }
  );

  const {
    data: productupdata,

    refetch: refetchupdate,
  } = useQuery(
    ["proupdata"],
    () =>
      api.get(
        `admin/productupdate/${proname}/${price}/${description}/${serviceid}/${subServiceid}/${proid}`
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,

      select: (res) => res.data,
    }
  );

  //

  const {
    data: servicedata,

    refetch: servicerefetch,
  } = useQuery(["services"], () => api.get(`admin/getallservices`), {
    select: (res) => res.data,
  });

  const {
    data: subservicedata,

    refetch: subservicerefetch,
  } = useQuery(
    ["subservices", serviceid],
    () => api.get(`admin/getsubservices/${serviceid}`),
    {
      select: (res) => res.data,
    }
  );

  const handleImagesSubmit = async () => {
    var formData = new FormData();
    formData.append("id", parseInt(proid));
    images &&
      images.forEach((item, index) => {
        formData.append("picture" + index, item);
      });
    const headers = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    var response = await api.post("admin/uploadimages", formData, headers);
    console.log(response.status);
    if (response.status) {
      toast.success("Image Updated Successfuly!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error("Failed to Edit Image!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleSubmit = () => {
    setUpload(false);
    refetchupdate();

    setTimeout(() => {
      setProid("");
      setDescription("");
      setPrice("");
      setProname("");
    }, 1000);
  };

  const handleImages = (files) => {
    var tempImages = [...images, ...files];
    // console.log(tempImages)
    setImages([...tempImages]);
  };

  const handleClose = () => {
    prorefetch();

    setShow(false);
  };

  const showDialog = (item) => {
    return (
      <div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Update Package</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Package Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Package Name"
                  value={proname}
                  onChange={(e) => setProname(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Amount"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Category Name</Form.Label>

                <Form.Select
                  aria-label="Default select example"
                  value={serviceid}
                  onChange={(e) => setServiceid(e.target.value)}
                  // className="mt-4"
                >
                  <option value="#">--select--</option>

                  {servicedata?.data.map((item) => {
                    return <option value={item.id}>{item.name}</option>;
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Sub Category Name</Form.Label>

                <Form.Select
                  aria-label="Default select example"
                  value={subServiceid}
                  onChange={(e) => setSubServiceid(e.target.value)}
                  // className="mt-4"
                >
                  <option selected value="0">
                    --select--
                  </option>

                  {subservicedata?.data.map((item) => {
                    return <option value={item.id}>{item.name}</option>;
                  })}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3 mt-3" controlId="formBasicPassword">
                <Form.Label>Image</Form.Label>

                <Dropzone
                  onDrop={(acceptedFiles) => handleImages(acceptedFiles)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>Upload Multiple Images</p>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </Form.Group>
              <Button variant="dark" onClick={() => handleSubmit()}>
                Update
              </Button>
              <Button
                className="mml"
                variant="dark"
                disabled={upload}
                onClick={() => handleImagesSubmit()}
              >
                Upload
              </Button>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  const handleEdit = (item) => {
    setProname(item.name);
    setPrice(item.price);
    setDescription(item.description);
    setServiceid(item.serviceid);
    setSubServiceid(item.subserviceid);
    setProid(item.id);

    setShow(true);
  };

  const columns = [
    {
      name: "S.No.",
      selector: (row, index) => index + 1,
      // sortable: true,
      width:"10%",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      // sortable: true,
      width:"60%",
    },
    {
      name: "Price",
      selector: (row) => row.price,
      // sortable: true,
      width:"10%",
    },
    
    {
      button: true,
      width: "200px",
      cell: (item) => {
        return (
          <div className="actionBtn">
            <button
                type="button"
                onClick={() => handleEdit(item)}
              >
                <i class="fa-solid fa-pen-to-square"></i>
              </button>
              <button
                type="button"
                onClick={() => {
                  handleDelete(item.id, "deleteproducts");
                  prorefetch()
                }}
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </div>
        );
      },
    },
  ];

  return (
    <div className="mainBody">
      <div class="innerPageSection">
        <div class="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="formSection">
                <div className="subHeading">
                  <h3>Packages</h3>
                </div>
                <Link to="/productadd">
                  <button type="button" className="btn btn-dark addBtn">
                    Add Package
                  </button>
                </Link>
                <DataTable columns={columns} data={prodata?.data} />
                
                {showDialog()}
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  );
};

export default ProductList;
