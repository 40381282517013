import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useQuery } from "@tanstack/react-query";
import DataTable from "react-data-table-component";
import api from "../Api/api";
import { handleDelete } from "../components/handleDelete";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const PackagePlan = () => {
  const [name, setName] = useState();
  const [price, setPrice] = useState();
  const [status, setStatus] = useState(0);
  const [type, setType] = useState();
  const [packid, setPackid] = useState();
  const [showAdd, setShowAdd] = useState(false);

  const [show, setShow] = useState(false);

  const {
    data,

    refetch,
  } = useQuery(
    ["prodata"],
    () => api.get(`admin/packageadd/${name}/${price}/${status}/${type}`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,

      select: (res) => res.data,
    }
  );

  const { data: packupdata, refetch: refetchupdatepack } = useQuery(
    ["packup"],
    () =>
      api.get(
        `admin/packageupdate/${name}/${price}/${status}/${type}/${packid}`
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,

      select: (res) => res.data,
    }
  );

  const { data: packdata, refetch: packrefetch } = useQuery(
    ["pdata"],
    () => api.get(`users/getpackageitems`),
    {
      select: (res) => res.data,
    }
  );

  useEffect(() => {
    packrefetch();
  }, [packupdata, data]);

  useEffect(() => {
    console.log(data?.status);
    if (data?.status) {
      toast.success("Package added Successfuly!", {
        position: toast.POSITION.CENTER,
      });
    } else if (data?.status == false) {
      toast.error("Server error!", {
        position: toast.POSITION.CENTER,
      });
    }
  }, [data]);

  const handleEditSubmit = () => {
    refetchupdatepack();
    packrefetch();
  };

  const handleSubmit = () => {
    refetch();
    packrefetch();
  };

  const handleClose = () => {
    setShow(false);
    packrefetch();
  };

  const showDialog = () => {
    return (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Update Option</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Form.Text className="text-muted"></Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Amount"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </Form.Group>

              <Form.Label>Package for?</Form.Label>

              <Form.Select
                // className="mt-3"
                aria-label="Default select example"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="#">--select--</option>

                <option value="Bride">Bride</option>
                <option value="Groom">Groom</option>
              </Form.Select>

              <Button
                className="mt-3"
                variant="dark"
                onClick={() => handleEditSubmit()}
              >
                Update
              </Button>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const handleEdit = (item) => {
    setName(item.name);
    setPrice(item.amount);
    setType(item.type);
    setPackid(item.id);
    setShow(true);
  };

  const columns = [
    {
      name: "S.No.",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Option Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: "Option For",
      selector: (row) => row.type,
    },
    {
      button: true,
      width: "200px",
      cell: (item) => {
        return (
          <div className="actionBtn">
              <button
                type="button"
                onClick={() => handleEdit(item)}
              >
                <i class="fa-solid fa-pen-to-square"></i>
              </button>
              <button
                type="button"
                onClick={() => {
                  handleDelete(item.id, "deletepackages");
                  packrefetch();
                }}
              >
                <i class="fa-solid fa-trash"></i>
              </button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="mainBody">
      <div class="innerPageSection">
        <div class="container-fluid">
          <div className="row">
            <div className="col-md-12">
                  <Button
                    className="btn add_nav_btn btn-primary"
                    onClick={() => setShowAdd(!showAdd)}
                  >
                    Add New
                  </Button>
                {showAdd && (
              <div className="formSection">
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Amount</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Amount"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Label>Package for?</Form.Label>

                    <Form.Select
                      // className="mt-3"
                      aria-label="Default select example"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option value="#">--select--</option>

                      <option value="Bride">Bride</option>
                      <option value="Groom">Groom</option>
                    </Form.Select>

                    <Button
                      className="mt-3"
                      variant="dark"
                      onClick={() => handleSubmit()}
                    >
                      Submit
                    </Button>
                  </Form>
              </div>
                )}
              <div className="formSection">
                <div className="subHeading">
                  <h3>Options List</h3>
                </div>
                <DataTable columns={columns} data={packdata?.data} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDialog()}
    </div>
  );
};

export default PackagePlan;
