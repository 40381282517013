import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import api from "../Api/api";
import { ToastContainer,toast } from "react-toastify";

const AddAboutUs = () => {
  const [description, setDescription] = useState();
  const [picture, setPicture] = useState();

  const mutationaboutus = useMutation(
    (body) => {
      const headers = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      return api.post("admin/updateaboutus", body, headers);
    },
    {
      onError: (error, variables, context) => {
        toast.error("server error", {
          position: toast.POSITION.TOP_CENTER,
        });
      },
      onSuccess: (data, variables, context) => {
        toast.success("About us updated", {
          position: toast.POSITION.TOP_CENTER,
        });
      },
    }
  );

  const handlePicture = (e) => {
    setPicture(e.target.files[0]);
  };

  const handleSubmit = () => {
    var formData = new FormData();
    formData.append("description", description);
    formData.append("picture", picture);
    setTimeout(() => {
      mutationaboutus.mutate(formData);
    }, 1000);
  };

  return (
    <div className="mainBody">
      <div class="innerPageSection">
        <div class="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="formSection">
                <div className="subHeading">
                  <h3>About Section</h3>
                </div>
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                    <Form.Text className="text-muted"></Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Image</Form.Label>
                    <Form.Control
                      type="file"
                      placeholder="upload"
                      onChange={(e) => handlePicture(e)}
                    />
                  </Form.Group>

                  <Button variant="dark" onClick={() => handleSubmit()}>
                    Update
                  </Button>
                </Form>
              </div>
            </div>
          </div>
          <ToastContainer/>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  );
};

export default AddAboutUs;
