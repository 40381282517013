import React from "react";

import DataTable from "react-data-table-component";
import { useQuery, useMutation } from "@tanstack/react-query";
import useAuth from "../hooks/useAuth";
import api from "../Api/api";



const Customers = () => {
  const { auth } = useAuth();

  const { data: custdata, refetch: custrefetch } = useQuery(
    ["custdata"],
    () => api.get(`admin/getcustomers`),
    {
      select: (res) => res.data,
    }
  );

  const columns = [
    {
      name: "S.No.",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => row.last_name,
      sortable: true,
    },
    {
      name: "mobile",
      selector: (row) => row.mobile,
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: "City",
      selector: (row) => row.city,
      sortable: true,
    },
  ];

  return (
    <div className="mainBody">
      <div class="innerPageSection">
        <div class="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="formSection">
                <div className="subHeading">
                  <h3>Customers</h3>
                </div>
          <DataTable
            // title="Customers"
            columns={columns}
            data={custdata?.data}
          />
        </div>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  );
};

export default Customers;
