import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import api, { imageapi } from "../Api/api";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { handleDelete } from "../components/handleDelete";
const SubServices = () => {
  const [serviceid, setServiceid] = useState();
  const [show, setShow] = useState(false);
  const [subServiceid, setSubServiceid] = useState();
  const [showImageDialog, setShowImageDialog] = useState(false);
  const [showAdd, setShowAdd] = useState(false);

  const [subServicename, setSubServicename] = useState();
  const [picture, setPicture] = useState();

  const { data: servicedata } = useQuery(
    ["services"],
    () => api.get(`admin/getservices`),
    {
      select: (res) => res.data,
    }
  );

  const { data: subservicedata, refetch } = useQuery(
    ["subdata"],
    () => api.get(`admin/getallsubservices`),
    {
      select: (res) => res.data,
    }
  );

  const mutationsubservices = useMutation(
    (body) => {
      const headers = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      return api.post("admin/addsubservices", body, headers);
    },
    {
      onError: (error, variables, context) => {
        console.log(error);
        toast.error("server error !", {
          position: toast.POSITION.TOP_CENTER,
        });
      },
      onSuccess: (data, variables, context) => {
        toast.success("Submitted!", {
          position: toast.POSITION.TOP_CENTER,
        });
        refetch();
      },
    }
  );

  const mutationimage = useMutation(
    (body) => {
      const headers = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      return api.post("admin/updatesubserviceimage", body, headers);
    },
    {
      onError: (error, variables, context) => {
        console.log(error);
        toast.error("server error !", {
          position: toast.POSITION.TOP_CENTER,
        });
      },
      onSuccess: (data, variables, context) => {
        toast.success("Image Updated!", {
          position: toast.POSITION.TOP_CENTER,
        });
        refetch();
      },
    }
  );

  const mutationsubservicesupdate = useMutation(
    (body) => {
      const headers = {
        headers: {
          "content-type": "application/json",
        },
      };
      return api.post("admin/updatesubservices", body, headers);
    },
    {
      onError: (error, variables, context) => {
        console.log(error);
        toast.error("server error !", {
          position: toast.POSITION.TOP_CENTER,
        });
      },
      onSuccess: (data, variables, context) => {
        toast.success("Submitted!", {
          position: toast.POSITION.TOP_CENTER,
        });
        refetch();
      },
    }
  );

  const handlePicture = (e) => {
    setPicture(e.target.files[0]);
  };

  const handleEditImageSubmit = () => {
    const formData = new FormData();
    formData.append("subserviceid", subServiceid);

    formData.append("picture", picture);
    mutationimage.mutate(formData);
  };

  const handleEditSubmit = () => {
    var formData = new FormData();
    formData.append("serviceid", serviceid);
    formData.append("subserviceid", subServiceid);

    formData.append("subservicename", subServicename);
    mutationsubservicesupdate.mutate(formData);
  };

  const handleSubmit = () => {
    var formData = new FormData();
    formData.append("serviceid", serviceid);

    formData.append("subservicename", subServicename);
    formData.append("picture", picture);
    setTimeout(() => {
      mutationsubservices.mutate(formData);
    }, 1000);
  };

  const handleEdit = (item) => {
    setServiceid(item.serviceid);
    setSubServicename(item.name);
    setSubServiceid(item.id);

    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    setShowImageDialog(false);
  };

  const handleEditImage = (item) => {
    setSubServiceid(item.id);
    setShowImageDialog(true);
  };

  const imageDialog = () => {
    return (
      <>
        <Modal show={showImageDialog} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Update Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Image</Form.Label>
                <Form.Control
                  type="file"
                  placeholder="upload"
                  onChange={(e) => handlePicture(e)}
                />
              </Form.Group>

              <Button
                className="mt-3"
                variant="dark"
                onClick={() => handleEditImageSubmit()}
              >
                Update
              </Button>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const showDialog = () => {
    return (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Update Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Label>Category Name</Form.Label>

              <Form.Select
                aria-label="Default select example"
                value={serviceid}
                onChange={(e) => setServiceid(e.target.value)}
              >
                <option value="#">--select--</option>

                {servicedata?.data.map((item) => {
                  return <option value={item.id}>{item.name}</option>;
                })}
              </Form.Select>

              <Form.Group className="mb-3 mt-3" controlId="formBasicEmail">
                <Form.Label>Sub Category Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  value={subServicename}
                  onChange={(e) => setSubServicename(e.target.value)}
                />
                <Form.Text className="text-muted"></Form.Text>
              </Form.Group>

              <Button variant="dark" onClick={() => handleEditSubmit()}>
                Submit
              </Button>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const columns = [
    {
      name: "S.No.",
      selector: (row, index) => index + 1,
      // sortable: true,
    },
    {
      name: "Sub Category Name",
      selector: (row) => row.name,
      // sortable: true,
    },
    {
      name: "Sub Category Image",
      selector: (row) => {
        return (
          <>
            <img
              src={`${imageapi}/images/${row.picture}`}
              width="100px"
              alt=""
            />
          </>
        );
      },
      sortable: true,
    },
    {
      button: true,
      width: "200px",
      cell: (item) => {
        return (
          <div className="actionBtn">
            <button type="button" onClick={() => handleEdit(item)}>
              <i class="fa-solid fa-pen-to-square"></i>
            </button>
            <button
              type="button"
              onClick={() => {
                handleDelete(item.id, "deletesubservices");
                refetch();
              }}
            >
              <i class="fa-solid fa-trash"></i>
            </button>
            <button
              type="button"
              className="btn2"
              onClick={() => handleEditImage(item)}
            >
              Edit Image
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="mainBody">
      <div class="innerPageSection">
        <div class="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Button
                className="btn btn-primary add_nav_btn"
                onClick={() => setShowAdd(!showAdd)}
              >
                Add New
              </Button>

                {showAdd && (
              <div className="formSection">
                  <Form>
                    <Form.Label>Category Name</Form.Label>

                    <Form.Select
                      aria-label="Default select example"
                      value={serviceid}
                      onChange={(e) => setServiceid(e.target.value)}
                    >
                      <option value="#">--select--</option>

                      {servicedata?.data.map((item) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}
                    </Form.Select>

                    <Form.Group
                      className="mb-3 mt-3"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Sub Category Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter name"
                        value={subServicename}
                        onChange={(e) => setSubServicename(e.target.value)}
                      />
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Image</Form.Label>
                      <Form.Control
                        type="file"
                        placeholder="upload"
                        onChange={(e) => handlePicture(e)}
                      />
                    </Form.Group>
                    <Button variant="dark" onClick={() => handleSubmit()}>
                      Submit
                    </Button>
                  </Form>
              </div>
                )}
              <div className="formSection">
                <div className="subHeading">
                  <h3>Sub Category List</h3>
                </div>
                <DataTable columns={columns} data={subservicedata?.data} />
              </div>
            </div>
          </div>
          {showDialog()}
          {imageDialog()}
        </div>
      </div>
    </div>
  );
};

export default SubServices;
