import React from "react";

const Dashboard = () => {
  return (
    <div className="mainBody">
      <div class="dashboardSection">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <h3 align="center">Welcome To Dashboard</h3>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  );
};

export default Dashboard;
