import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import api from "./Api/api";
import { Link, useNavigate } from "react-router-dom";

const FeatureProduct = () => {
  const [productid, setProductid] = useState();

  const { data: productdata, refetch } = useQuery(
    ["pdata"],
    () => api.get(`admin/getproductlist`),
    {
      select: (res) => res.data,
    }
  );

  const { data: featureadd, refetch: featureRefetch } = useQuery(
    ["featureadd"],
    () => api.get(`admin/addfeature/${productid}`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      select: (res) => res.data,
    }
  );

  const handleSubmit = () => {
    featureRefetch();
  };

  return (
    <div className="mainBody">
      <div class="innerPageSection">
        <div class="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="formSection">
                <div className="row">
                  <div className="col-md-6 form-group">
                    <Form.Select
                      aria-label="Default select example"
                      value={productid}
                      onChange={(e) => setProductid(e.target.value)}
                    >
                      <option value="#">--Select--</option>

                      {productdata?.data.map((item) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}
                    </Form.Select>
                  </div>
                  <div className="col-md-12">
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="btn btn-dark addBtn"
                    >
                      Add
                    </button>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureProduct;
