import { useMutation, useQuery } from "@tanstack/react-query";

import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

import api from "../Api/api";
import useAuth from "../hooks/useAuth";

const Banner = () => {
  const [title, setTitle] = useState();
  const [tagline, setTagline] = useState();
  const [picture, setPicture] = useState();
  const { auth } = useAuth();

  const { data: bannerdata, refetch } = useQuery(
    ["banners"],
    () => api.get(`admin/getbanners`),
    {
      select: (res) => res.data,
    }
  );
  useEffect(() => {
    setTitle(bannerdata?.data.title);
    setTagline(bannerdata?.data.tagline);
  }, [bannerdata]);

  console.log(auth);
  const mutationsubmit = useMutation(
    (body) => {
      const headers = {
        headers: {
          "content-type": "application/json",
        },
      };
      return api.post("admin/updatebanner", body, headers);
    },
    {
      onError: (error, variables, context) => {
        console.log(error);
        toast.error("select Image!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      },
      onSuccess: (data, variables, context) => {
        toast.success("Banner Upadated", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        console.log(true);
      },
    }
  );

  const handleSubmit = () => {
    var formData = new FormData();
    formData.append("title", title);
    formData.append("tagline", tagline);
    formData.append("picture", picture);
    mutationsubmit.mutate(formData);
  };

  return (
    <div className="mainBody">
      <div class="innerPageSection">
        <div class="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="formSection">
                <div className="subHeading">
                  <h3>Banner</h3>
                </div>
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Tagline</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Tagline"
                      value={tagline}
                      onChange={(e) => setTagline(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Control
                      type="file"
                      onChange={(e) => setPicture(e.target.files[0])}
                    />
                  </Form.Group>
                  <Button variant="dark" onClick={() => handleSubmit()}>
                    Update
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  );
};

export default Banner;
